import React from "react"
import { graphql } from "gatsby"
import Footer from "../components/Footer"
import SEO from "../components/seo";
import Header from "../components/Header";
import Faq from "../components/Faq";
import PAGEDATA from "../../content/privacy.json";
import PageWrapper from "../PageWrapper";

const PrivacyIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <PageWrapper>
      <Header
        component={
          <div className="header-hero bg_cover d-lg-flex align-items-center pb-5 pb-sm-0">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="header-hero-content">

                    {/* <!--====== FIRST SECTION START======--> */}

                    <div className="row justify-content-center">
                      <div className="col-lg-9">
                        <div className="about-title">
                          <h1 className="privacy--welcome text-center">
                            Privacy policy
                          </h1>
                          <p className="pt-5 px-5 mb-3">
                            This privacy policy sets out how lexiQA uses and
                            protects any information that you give lexiQA when
                            you create and use a personal account to access
                            lexiQA’s service.
                          </p>
                          <p className="px-5 mb-3">
                            In the context of the GDPR, lexiQA operates only as
                            a ‘processor’ of the personal data you provide to
                            us. lexiQA’s operations and privacy policy are
                            compliant with the UK Data Protection Act of 2018
                            and the EU General Data Protection Regulation, which
                            came into effect on 25 May 2018.{" "}
                          </p>
                          <p className="px-5">
                            The information below outlines how we collect, use
                            and retain personal data by adhering to GDPR
                            requirements of notice, choice, onward transfer,
                            security, data integrity, access and enforcement.
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <!--======FIRST SECTION END======--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        title={siteTitle}
      />
      <SEO title="Privacy" />

      {/* <!--====== FAQ (DARK) PART START ======--> */}
      <section className="faq-area pt-70 pb-70 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 pb-5">
              <div className="faq-content">
                <div className="faq-accordion faq-accordion-6">
                  <div className="accordion" id="accordionExample">
                    {PAGEDATA.faq.map((qa, idx) => {
                      return <Faq 
                              data={qa} 
                              idx={idx} 
                              key={qa.question}
                              isOpen={idx === 0 ? true : false} 
                              />
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--====== FAQ (DARK) PART ENDS ======--> */}

      <Footer />
    </PageWrapper>
  )
}

export default PrivacyIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
