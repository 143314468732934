import React from 'react';
import "./Faq.scss";

function Faq({ data, idx, isOpen }) {

    const spansMultipleLines = Array.isArray( data.answer );

    return (
        <div className="card">
            <div className="card-header" id={ "heading" + idx }>
                <a href="#idx"
                    data-toggle="collapse"
                    className={ isOpen ? "" : "collapsed" }
                    data-target={ "#collapse" + idx } aria-expanded={ isOpen ? "true" : "false" }
                    aria-controls={ "collapse" + idx }>{ data.question }</a>
            </div>

            <div
                id={ "collapse" + idx }
                className={ "collapse" + ( ( isOpen ) ? " show" : "" ) }
                aria-labelledby={ "heading" + idx }
                data-parent="#accordionExample">
                <div className="card-body">
                    {
                        spansMultipleLines ?
                            data.answer.map( ( answer, idx, array ) => 
                            <p 
                                    key={ idx } 
                                    className={ 
                                        "text" + ( ( array.length-1 !== idx ) ? " mb-4" : "" ) 
                                    }
                                    dangerouslySetInnerHTML={{
                                        __html: answer
                                      }}
                                ></p> 
                            ) 
                            :
                            <p 
                            className="text"
                            dangerouslySetInnerHTML={{
                                __html: data.answer
                              }}></p>
                    }
                </div>
            </div>
        </div>

    );

}

export default Faq;