import React from 'react';
import Helmet from "react-helmet";

function PageWrapper( props ){

    const isBrowser = typeof window !== `undefined`;
    let pagepath = isBrowser ? window.location.pathname.replace( /\//g, "" ) : "";
    pagepath = ( pagepath === "" ) ? "index" : pagepath;

    return (
        <>
            <Helmet bodyAttributes={{ class: 'page-' + pagepath }} />
            { props.children }
        </>
    );

}

export default PageWrapper;